import {
  Box,
  Divider,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Page } from '../../components/Page';
import { Button } from '../../components/styled';
import { useAccountSelectorLogic } from './useAccountSelectorLogic';
import { IamAccount } from '@jupiterone/login-shared';

export const useStyles = makeStyles(() => ({
  centeredText: {
    marginTop: 15,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
  },
  center: {
    margin: 0,
    textAlign: 'center',
    justifyContent: 'center',
    display: 'contents',
  },
  mfa: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
    textAlign: 'center',
  },
  flexDivider: {
    width: '100%',
    margin: 12,
  },
  showCode: {
    fontSize: '0.8rem',
    padding: '4px',
    borderRadius: '4px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.1)',
    whiteSpace: 'pre-wrap',
  },
  verification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '12px',
  },
}));

export function AccountSelector() {
  const classes = useStyles();
  const { accounts, loading } = useAccountSelectorLogic();

  const login = (account: IamAccount) => {
    const { accountId } = account;

    const redirect = `/account/${accountId}${window.location.search}`;
    location.href = redirect;
  };

  const logout = () => {
    //Set subdomain to nothing to pass through to '/'
    location.href = '/auth/logout';
  };

  return loading ? (
    <LinearProgress />
  ) : (
    <Page title="Select an account" centerTitle={true} showRightColumn={false}>
      <Box pt={1} maxWidth={200} className={classes.center} maxHeight={50}>
        {accounts.map((account) => (
          <Button
            key={account.accountId}
            data-testid={`account-selector-account-id-${account.accountId}`}
            onClick={() => login(account)}
          >
            <span>
              <span>{account.accountName}</span>
            </span>
          </Button>
        ))}
        <Divider orientation="horizontal" className={classes.centeredText} />
        <Typography className={classes.centeredText}>
          <Button data-testid={`account-selector-cancel`} onClick={logout}>
            Cancel
          </Button>
        </Typography>
      </Box>
    </Page>
  );
}
