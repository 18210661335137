import {
  Box,
  FormHelperText,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { cognitoSessionToTokenSession } from '~/src/utils/cognitoUtils';
import { useAwsApi, useLoginApi } from '../../../hooks';
import { Button, Form, Link, TextField } from '../../../components/styled';

export function EmailLogin() {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [promptForMfaCode, setPromptForMfaCode] = React.useState(false);
  const [mfaCode, setMfaCode] = React.useState('');
  const [error, setError] = React.useState('');
  const { loginWithCredentials, sendMfaCode } = useAwsApi();
  const { setSession } = useLoginApi();

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setError('');
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setError('');
  };

  const sendVerification = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const session = await sendMfaCode(mfaCode);
      const tokenSession = cognitoSessionToTokenSession(session);

      await setSession({
        session: tokenSession,
      });

      document.location.assign(`/account-selector${window.location.search}`);
    } catch (err) {
      setError('MFA Code not valid. Try again.');
      setLoading(false);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const { session, totp } = await loginWithCredentials(username, password);
      if (!session) {
        // TODO: add email challenge checker
        if (totp.challengeName === 'SOFTWARE_TOKEN_MFA') {
          setLoading(false);
          setPromptForMfaCode(true);
          return;
        }
        throw new Error('Unrecognized login error');
      }

      const tokenSession = cognitoSessionToTokenSession(session);

      await setSession({
        session: tokenSession,
      });

      document.location.assign(`/account-selector${window.location.search}`);
    } catch (err) {
      setError('Unable to login. Try again or contact support.');
      setLoading(false);
    }
  };

  const wrapHandleFormSubmit = (event: React.FormEvent) => {
    handleFormSubmit(event).catch((err) => {
      setError(err.message);
    });
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {promptForMfaCode ? (
        <Box display="flex" flexDirection="column">
          <Typography variant="subtitle1">
            Enter your MFA verification code from your Authenticator app:
          </Typography>
          <Form onSubmit={sendVerification} id="login-mfa">
            <TextField
              label="MFA Code"
              onChange={(e) => setMfaCode(e.target.value)}
              value={mfaCode}
            />
            <Button disabled={!mfaCode || isLoading}>Submit</Button>
          </Form>
          <Typography variant="subtitle2">
            Lost access to your Authenticator app? Contact an account
            administrator to reset your MFA.
          </Typography>
        </Box>
      ) : (
        <Form onSubmit={wrapHandleFormSubmit} id="login-sign-in">
          <Tooltip title="case-sensitive">
            <TextField
              type="username"
              label="Email / Username"
              onChange={handleUsernameChange}
              value={username}
              autoFocus
            />
          </Tooltip>
          <TextField
            label="Password"
            onChange={handlePasswordChange}
            type="password"
            value={password}
          />
          <Button disabled={!username || !password || isLoading}>
            Sign In
          </Button>
        </Form>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
      {!promptForMfaCode && (
        <Box display="flex" justifyContent="space-between">
          <Link to="/forgot-password">Forgot Password?</Link>
          <Link to="/sign-up">Sign up</Link>
        </Box>
      )}
    </>
  );
}
