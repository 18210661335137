import { IamAccount } from '@jupiterone/login-shared';
import { useCallback, useState } from 'react';

export const useAccounts = () => {
  const [accounts, setAccounts] = useState<IamAccount[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();

  const getAccounts = useCallback(async () => {
    const response = await fetch('/accounts', {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 200) {
      const accounts = await response.json();
      setAccounts(accounts);
    } else {
      const error = await response.json();
      setError(error.message);
    }
    setLoading(false);
  }, []);

  return {
    accounts,
    loading,
    error,
    getAccounts,
  };
};
