import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
  UserData,
} from 'amazon-cognito-identity-js';
import 'cross-fetch/polyfill';
import React from 'react';

const CLIENT_ID = window.defaultPublicClientId;
const USER_POOL_ID = window.defaultUserPoolId;

const userPool = new CognitoUserPool({
  UserPoolId: USER_POOL_ID,
  ClientId: CLIENT_ID,
});

interface LoginResult {
  session: CognitoUserSession | undefined;
  totp: any;
  cognitoUser?: CognitoUser;
}

export interface LoginData {
  session: CognitoUserSession;
  userData?: UserData;
  secretCode?: string;
}

export function useAwsApi() {
  const [mfaCognitoUser, setMfaCognitoUser] = React.useState<CognitoUser>();
  return {
    loginWithCredentials: async (
      username: string,
      password: string,
    ): Promise<LoginResult> => {
      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      const { session, totp } = await loginUser({
        cognitoUser,
        username,
        password,
      });

      setMfaCognitoUser(cognitoUser);

      return { session, totp };
    },
    changePassword: async (params: {
      username: string;
      oldPassword: string;
      newPassword: string;
    }) => {
      const { username, oldPassword, newPassword } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      await loginUser({ cognitoUser, username, password: oldPassword });

      return new Promise((resolve, reject) => {
        cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err) {
            return reject(new Error(`Unable to change password`));
          }
          resolve('Password successfully changed');
        });
      });
    },
    forgotPassword: async (params: { username: string }) => {
      const { username } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: resolve,
          onFailure: reject,
        });
      });
    },
    confirmForgotPassword: async (params: {
      username: string;
      newPassword: string;
      code: string;
    }) => {
      const { username, newPassword, code } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, newPassword, {
          onFailure: reject,
          onSuccess: () => resolve(undefined), // onSuccess type is not the same as resolve
        });
      });
    },
    signUp: async (params: {
      username: string;
      email: string;
      password: string;
    }): Promise<ISignUpResult> => {
      const { username, password, email } = params;

      return new Promise((resolve, reject) => {
        userPool.signUp(
          username,
          password,
          [new CognitoUserAttribute({ Name: 'email', Value: email })],
          [new CognitoUserAttribute({ Name: 'email', Value: email })],
          (err, result) => {
            if (err) {
              return reject(err);
            }
            resolve(result!);
          },
        );
      });
    },
    confirmSignUp: async (params: { username: string; code: string }) => {
      const { username, code } = params;

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, false, (err, result) => {
          if (err) {
            return reject(err);
          }
          resolve(result);
        });
      });
    },
    getCurrentUserSession: async () => {
      const user = userPool.getCurrentUser();
      if (!user) throw new Error('user not logged in');
      return new Promise<CognitoUserSession>((resolve, reject) => {
        user.getSession((err: Error | null, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
    },
    getUserMfa: async (session: CognitoUserSession): Promise<LoginData> => {
      const user = userPool.getCurrentUser();
      if (!user) throw new Error('user not logged in');
      return new Promise((resolve, reject) => {
        user.refreshSession(
          session.getRefreshToken(),
          (sessionError, session) => {
            if (sessionError) {
              reject(sessionError);
            } else {
              user.getUserData(
                (error, userData) => {
                  if (error) {
                    reject(error);
                  } else {
                    if (
                      !userData?.UserMFASettingList?.includes(
                        'SOFTWARE_TOKEN_MFA',
                      )
                    ) {
                      user.associateSoftwareToken({
                        associateSecretCode(secretCode) {
                          resolve({ secretCode, userData, session });
                        },
                        onFailure: reject,
                      });
                    } else {
                      resolve({ userData, session });
                    }
                  }
                },
                { bypassCache: true },
              );
            }
          },
        );
      });
    },
    sendMfaCode: async (mfaCode: string): Promise<CognitoUserSession> => {
      return new Promise((resolve, reject) => {
        if (!mfaCognitoUser) {
          throw new Error('user not logged in');
        }
        mfaCognitoUser.sendMFACode(
          mfaCode,
          {
            onSuccess: resolve,
            onFailure: reject,
          },
          'SOFTWARE_TOKEN_MFA',
          {},
        );
      });
    },
  };
}

function loginUser(params: {
  cognitoUser: CognitoUser;
  username: string;
  password: string;
}) {
  const { cognitoUser, username, password } = params;

  return new Promise<LoginResult>((resolve, reject) => {
    cognitoUser.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
      }),
      {
        onSuccess(session, userConfirmationNecessary) {
          if (userConfirmationNecessary) {
            return reject(new Error('User confirmation necessary'));
          }
          resolve({ session, totp: undefined });
        },
        onFailure: reject,
        totpRequired(challengeName, challengeParameters) {
          resolve({
            session: undefined,
            totp: { challengeName, challengeParameters, cognitoUser },
          });
        },
      },
    );
  });
}
