import { useEffect, useState } from 'react';
import { LoginData, useAwsApi } from './useAwsApi';

export function useMfa({ accountId }: { accountId?: string }) {
  const { getCurrentUserSession, getUserMfa } = useAwsApi();
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<LoginData>();

  useEffect(() => {
    if (!accountId) return;
    setLoading(true);
    getCurrentUserSession()
      .then(getUserMfa)
      .then(setLoginData)
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  }, [accountId]);

  return { ...loginData, error, loading };
}
